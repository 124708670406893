import './Home.css';
import React from 'react';
import background2 from './background2.jpg'
import background3 from '../images/bg_3.png'
import homepagepic from './homepagepic.jpg';
import Header from './Header';
import bgggg from './bgggg.jpg';
import Item from './../components/Item';
import arrow from './../images/arrow.svg'
import Heartbeat from './../images/Heartbeat.svg'
import ai from  './../images/ai.svg'
import chartincrease from './../images/chartincrease.svg'
import divider from './../images/divider.svg'
import horizontaline from './../images/horizontaline.svg'
import Second_Page_item from '../components/Second_Page_Item';
import Three from '../components/Three'

import Button from '../components/Button';

function Home() {
  return (
    <div className="home">
      <Header />
      
      <div className='fixed-bottom d-flex justify-content-end'>
        <Button imp='primary' text="Athena Free Demo" link="" />
      </div>
      <div className='home_itemsContainer'>
        <Item id='home'
          title = ""
          desc = ""
          descLink=''
          backgroundImg={bgggg}
          representation=''
          rightBtnTxt='Athena Free Demo'
          rightBtnLink=''
          down_arrow={arrow}
          item_bottom_benefits_of_athena_header="Athena Delivers Tomorrow’s Customers Today."
          benefit_number_one="Autonomous buisness health optimization"
          benefit_number_one_img={Heartbeat}
          benefit_number_two_img={chartincrease}
          benefit_number_two_text="Autonomous Marketing Operations"
          benefit_number_three_img={ai}
          benefit_number_three_text="Autonomous Insight Discovery"
        /> 
        <Second_Page_item
          page_title = "What. Who. Is Apac Media?"
          desc = ''
          backgroundImg={background2}
          
        />
        <Three
          page_title = "What is Athena?"
          desc = ''
          backgroundImg={background3}          
        />

        {/* <Item id="athena_features"
          title = ""
          desc = ''
          descLink=''
          backgroundImg={background2}
          rightBtnTxt='Athena In ACTION'
          rightBtnLink=''
        />
          <Item id="athena_ai"
          title = ""
          desc = ''
          descLink=''
          backgroundImg={background2}
          rightBtnTxt='ATHENA DEMO'
          rightBtnLink=''
        />
        <Item id="get_started"
          title = ""
          desc = ''
          descLink=''
          backgroundImg={background2}
          rightBtnTxt='ATHENA DEMO'
          rightBtnLink=''
        /> */}
      </div>
    </div>
  );
};

export default Home;
