import React from 'react'
import frame1 from './../images/frame1.png'
import frame2 from './../images/frame2.jpg'
import frame3 from './../images/frame3.jpg'
import frame4 from './../images/frame4.jpg'

import user_1_ from './../images/user_1_.svg'
import checkmark from './../images/checkmark.svg'
import shape from './../images/shape.svg'
import './Second_Page_item.css';
import Button from './Button';


const Second_Page_Items = ({ backgroundImg, page_title }) => {
    return (
        <div className='second_page_item h-100 d-flex align-items-center' style={{ backgroundImage: `url(${backgroundImg})`}}> 
            <div className='container second_item_container'>
                <div className="row">
                    <div className='col section-title'>
                        <h2>{page_title}</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-12'>
                        <div className='card h-100'>
                            <h3>
                                <img src={shape} alt=''></img>
                                Our mission 
                            </h3>
                            <p>
                                OUR MISSION IS TO HELP OTHER COMPANIES reach their mission through ai.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-lg-0 mt-3'>
                        <div className='card h-100'>
                            <h3>
                                <img src={user_1_} alt=''></img>
                                Who we are
                            </h3>
                            <p>
                                We are a group of the world’s most dedicated and resilent engineers and problem solvers.
                            </p>
                        </div>                        
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-12'>
                        <div className='card h-100'>
                            <h3>
                                <img src={checkmark} alt=''></img>
                                What we’re up to 
                            </h3>
                            <p>
                                APAC Media is creating Athena, the solution to every marketing you could ever have within your business.
                            </p>
                            <p>
                                We are entering an era of continuous disruption.
                            </p>
                            <p>
                                In this context, apac media recognizes our customers' existential need to utilize software to generate competitive advantage — or, alpha. Everyone else will find themselves in a perpetual game of catchup.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-lg-0 mt-3'>
                        <div className='card h-100 justify-content-center'>
                            <h3 style={{ textAlign: 'center'}}>
                                A message from our founder
                            </h3>
                            <p className="d-flex justify-content-center button-no-margin">
                                <Button imp='primary' text="WATCH NOW >" link="" />
                            </p>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>    
    );
};

export default Second_Page_Items;