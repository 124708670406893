import React from 'react';
import './Item.css';
import Heartbeat from './../images/Heartbeat.svg'
import ai from  './../images/ai.svg'
import chartincrease from './../images/chartincrease.svg'
import divider from './../images/divider.svg'
import arrow from './../images/arrow.svg'

const Item = ({ title, desc, backgroundImg, rightBtnTxt, rightBtnLink, item_bottom_benefits_of_athena_header, benefit_number_one_img, down_arrow, benefit_number_one, benefit_number_two_img, benefit_number_two_text, benefit_number_three_img, benefit_number_three_text, item_bottom_benefits_of_athena_dividers_vertical, item_bottom_benefits_of_athena_dividers_vertical_2, item_bottom_benefits_of_athena_dividers_horizontal }) => {
    return (
        <div className='item' style={{
            backgroundImage: `url(${backgroundImg})`}}>
            <div className='item_container'>
                <div className='item_text_title'>
                    <p>{title}</p>
                    <div className='item_text_desc'>
                        <p>{desc}</p>
                    </div>
                </div>
                
                <div className='down_arrow'>
                    <img src={arrow} alt=''/>
                </div>

                <div className='container h-100 content-container'>
                    <div className='row'>
                        <div className='col-12 benefits-heading'>
                            <p style={{textAlign:"center"}}>{item_bottom_benefits_of_athena_header}</p>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-xl-3 col-12 benefit'>
                            <div className='row'>
                                <div className='col-3 d-flex justify-content-center'>
                                    <img src={benefit_number_one_img} alt="" /> 
                                </div>
                                <div className='col-8 d-flex align-items-center '>
                                    <p>{benefit_number_one}</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-xl-3 col-12 benefit'>
                            <div className='row'>
                                <div className='col-3 d-flex justify-content-center'>
                                    <img src={benefit_number_two_img} alt="" />
                                </div>
                                <div className='col-8 d-flex align-items-center '>
                                    <p>{benefit_number_two_text}</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-xl-3 col-12 benefit'>
                            <div className='row'>
                                <div className='col-3 d-flex justify-content-center'>
                                    <img src={benefit_number_three_img} alt="" />
                                </div>
                                <div className='col-8 d-flex align-items-center '>
                                    <p>{benefit_number_three_text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    );
}

export default Item;





