import './App.css';
import React from 'react';
import { HashRouter as Router, Routes , Route, Link } from "react-router-dom";
import Home from './page/Home';




export default function App() {
  return (
    <Home />
  );

}



function Athena() {
  return <h2>Athena</h2>;
}


