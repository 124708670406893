import React from 'react'
import frame1 from './../images/frame1.png'
import frame2 from './../images/frame2.jpg'
import frame3 from './../images/frame3.jpg'
import frame4 from './../images/frame4.jpg'

import athena_flow from './../images/athena_flow.png'


const Three = ({ backgroundImg, page_title }) => {
    return (
        <div className='h-100 d-flex align-items-center second_item_container' style={{ backgroundImage: `url(${backgroundImg})`}}>
            <div className="container"> 
                <div className="row">
                    <div className='col section-title'>
                        <h2>{page_title}</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-12'>
                        <div className='row mt-0'>
                            <div className='col'>
                                <div className="card">
                                    ATHENA IS A SEMI-ALIVE PLATFORM WITH ONE PURPOSE which is To help your company flourish..
                                </div> 
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <div className="card">
                                    <h3>
                                        THE PROBLEM(S)
                                    </h3>
                                    <p>
                                        MARKETING IN ITS CURRENT STATE IS INEFFECTIVE. 
                                    </p>
                                    <p>
                                        DATA IS DECENTRALIZED.
                                    </p>
                                    <p>
                                        CUSTOMERS DON’T FEEL LIKE THEY’RE BEING SPOKEN TO DIRECTLY.
                                    </p>
                                    <p>
                                        digging up insight that could be impactful takes weeks and months to discover then twice that to deploy.
                                    </p>
                                    <p>
                                        DECISION-MAKING IN MARKETING OPERATIONS SLOWS GROWTH.
                                    </p>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-2 mt-lg-0">
                        <div className='card h-100 justify-content-center'>
                            <img style={{width:'100%'}}src={athena_flow} alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Three;