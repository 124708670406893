import React from 'react';
import './Header.css';
import owl from './owl.svg'
import login from './login.svg'
import { Link } from 'react-router';


const Header = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top pe-4 d-flex">
      <a className="navbar-brand ps-3" href="#">
          <img src={owl} alt="APAC Media Logo" />
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end align-items-center" id="navbarCollapse">
        <ul className="navbar-nav mr-auto d-flex align-items-center">
          <li className="nav-item active">
            <a className="nav-link" href="#">Athena</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Blog</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Login <img src={login} alt='header_login_symbol'></img></a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header;